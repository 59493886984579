import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Layout } from '../Layout/Layout';
import PropTypes from 'prop-types';


export default function QuestionDetails() {
    // console.log(this.props)
    const [answerSuggestions, setAnswerSuggestions] = useState([]);
    
    const searchParams = new URLSearchParams(window.location.search);
    const question_id = searchParams.get("question_id") 
    const question = searchParams.get("question") 

    useEffect(() => {
        getAnswerSuggestions(question_id);
    },[setAnswerSuggestions]);

    const getAnswerSuggestions = (question_id) =>{
        if(navigator.onLine){
            return fetch(process.env.REACT_APP_API_URL + 'jobs/answers/list/?question_id='+question_id, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then(data => data.json()).then(result => {
                console.log("suggestion result.data",result.data);                
            });
        }
        else{
            Swal.fire('No Network Connection.');
        }
    }
    return (
        <>
         <Layout>
        <ul>
            <li>{question}</li>
            {
                answerSuggestions.length <= 0 ? 
                <p className='justify-content-center mt-3'><b> No Answer Found!</b></p> :            
                answerSuggestions.map( (suggestion, i) => {                    
                    <li>{suggestion}</li>
                }) 
            }
        </ul>
        </Layout>
        </>
    )}
