import DataTable from 'react-data-table-component';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";

const customStyles = {
    rows: {
        style: {
            minHeight: '40px',
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
                outline: '1px solid #FFFFFF',
            },
        },
    },
    headCells: {
        style: {
            height: '40px',
            paddingLeft: '8px',
            paddingRight: '8px',
            color: '#202124',
            fontSize: '14px',
            fontWeight: 600,
        },
        grow: 2,
    },
    cells: {
        style: {},
    },
};

export const DataTableComp = ({ columns, data, ...rest }) => {
    const [search, setSearch] = useState('');
    const [filteredData, setFilteredData] = useState(data);

    useEffect(() => {
        setFilteredData(data);
    }, [data]);

    const handleSearch = (event) => {
        const value = event.target.value.toLowerCase();
        setSearch(value);

        const filtered = data.filter(row => {
            return columns.some(column => {
                let columnValue = '';

                if (typeof column.selector === 'function') {
                    columnValue = column.selector(row);
                } else if (typeof column.selector === 'string') {
                    columnValue = row[column.selector];
                } else if (column.cell && typeof column.cell === 'function') {
                    const cellContent = column.cell(row);
                    if (React.isValidElement(cellContent)) {
                        columnValue = cellContent.props.children.toString();
                    } else {
                        columnValue = cellContent.toString();
                    }
                }

                // Log each column and its value for debugging
                // console.log(`Row ID: ${row.id}, Column Selector: ${column.selector}, Column Value: ${columnValue}`);

                if (columnValue) {
                    return columnValue.toString().toLowerCase().includes(value);
                }

                return false;
            });
        });

        setFilteredData(filtered);
    };

    const subHeaderComponent = (
        <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={handleSearch}
            className="form-control form-control-sm"
            style={{ width: '300px' }}
        />
    );

    return (
        <DataTable
            pagination
            columns={columns}
            customStyles={customStyles}
            sortIcon={<ArrowDropUpIcon />}
            data={filteredData}
            // subHeader
            // subHeaderComponent={subHeaderComponent}
            {...rest}
        />
    );
}

export default DataTableComp;

DataTableComp.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired
};
