import React, { useEffect, useRef, useState } from "react";
import { useFormik } from 'formik';

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

export const KeywordModal = ({type, show, handleClose, defaultKeyword, keywordId, refreshKeywords,isFlashError, setIsFlashError, flashErrorMsg, setFlashErrorMsg, ...rest}) => {

  const submitButton = useRef();
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [keyword, setKeyword] = useState('');

  const handleChange = (e) => {
    // console.log(e.currentTarget.value);
    setKeyword(e.currentTarget.value);
    setIsError(false);
    submitButton.current.removeAttribute('disabled');
  }

  const handleSave = () => {
     if(keyword.trim() !== ''){
       fetch(process.env.REACT_APP_API_URL + 'jobs/addkeyword', {
         method: 'PUT',
         headers: {
             'accept': '*/*',
             'Content-Type': 'application/json',
             'token': localStorage.getItem('token')
         },
         body: JSON.stringify({'keyword': keyword,'keyword_id':keywordId})
       }).then(data => data.json().then((result) => {
          if(result.success){
            handleClose();
            setIsError(false);
            setIsFlashError(false);
            setFlashErrorMsg(result.message);
            refreshKeywords();
          }
          else{
            // setIsFlashError(true);
            console.log(result.message);
            setErrorMsg(result.message);
            setIsError(true);          
            submitButton.current.setAttribute('disabled','disabled');  
          }
       }).catch((err) => {
           console.log(err);
       }));
     }
     else{
      setErrorMsg('Please fill the keyword');
      setIsError(true); 
      submitButton.current.setAttribute('disabled','disabled'); 
     }
   };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{type} Keyword</Modal.Title>
          </Modal.Header>
          <Form>
            <Modal.Body>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
                >
                <Form.Label>Keyword</Form.Label>
                <Form.Control
                  name = "keyword"
                  type="text"
                  defaultValue={defaultKeyword}
                  required
                  placeholder="Enter keyword"
                  onChange={(e) => {handleChange(e)}}
                  onBlur={(e) => {handleChange(e)}}
                  autoFocus
                  />
                  <div className="error text-danger" style={{ display: isError === true ? 'block' : 'none' }}>{errorMsg}</div>
              </Form.Group>
              <p style={{fontSize:'10px',}}>*To Add multiple keyword use | symbol as seprater.</p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
              Close
              </Button>
              <Button type="button" variant="primary" ref={submitButton} onClick={handleSave}>
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
    </>
  );
}

export default KeywordModal;

KeywordModal.propTypes = {
    
};
