import React, { useState }  from 'react';
import PropTypes from "prop-types";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    email: yup
    .string('Enter your email')
    .email('Enter a valid email')
    .required('Email is required')
}); 
export const Add = ({type, show, setShow, handleClose,getList, ...rest}) => {   
    const initialValues = {
        email: '',
    }
        
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSave(values);
        }
    });

    const handleSave = (postData) =>{
        console.log("postData",postData)
        return fetch(process.env.REACT_APP_API_URL + 'savenotificationemail', {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify(postData)
        }).then(data => data.json()).then(res => {
            if(res.success){
                setShow(false);
                Swal.fire(res.message, '', 'success').then(result => {
                    getList();
                    formik.values.email = '';
                })                
            } 
            else{
                Swal.fire(res.message, '', 'error')
            }
        })
        .catch((error) => {
            Swal.fire(error, '', 'error')
        });    
    }
    return (
        <Modal show={show} onHide={handleClose} dialogClassName="modal-md" style={{zIndex: 9998}}>
            <Modal.Header>
                <h5 className="modal-title">{type} Email</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                    <span aria-hidden="true">&times;</span>
                </button>
            </Modal.Header>
            <form onSubmit={formik.handleSubmit}>
                <Modal.Body>
                    <div className="form-group">
                        <label htmlFor="">Email</label>
                        <input name="email" className="form-control" onChange={formik.handleChange} defaultValue={formik.values.email} />
                        {formik.touched.email && formik.errors.email ? <div className="text-danger">{formik.errors.email}</div> : null}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="r-btn r-btn-base" onClick={handleClose}>Close</button>
                    <button type="submit" className="r-btn r-btn-primary">{type === 'Add' ? 'Add' : 'Update'}</button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default Add;
Add.propTypes = {
    type: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired    
};