import React, { useState } from 'react';
import JobTile from '../JobTile/JobTile';
import { Layout } from '../Layout/Layout';
import Loader from '../Loader/Loader';

export default function JobAccepted() {

    return (
        <>
        <Layout>
            <JobTile title="Accepted Jobs" type="1" />
        </Layout>
        </>
    )
}