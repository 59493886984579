import React, { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import { Layout } from "../Layout/Layout";
import Button from "react-bootstrap/Button";
import KeywordModal from "./KeywordModal";

import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content'
// const MySwal = withReactContent(Swal)

export default function KeywordList() {
  const [selectedRows, setSelectedRows] = useState([]);
  const [show, setShow] = useState(false);
  const [keywordId, setKeywordId] = useState(0);
  const [defaultKeyword, setDefaultKeyword] = useState("");
  const [modalType, setModalType] = useState("Add");
  const [res, setRes] = useState([]);

  const [isFlashError, setIsFlashError] = useState("");
  const [flashErrorMsg, setFlashErrorMsg] = useState("");

  const handleChange = (value) => {
    setSelectedRows(value);
  };

  const handleClose = () => setShow(false);
  const showAddModel = () => {
    setShow(true);
    setModalType("Add");
    setDefaultKeyword("");
    setKeywordId(0);
  };

  const editRow = (event, keywordName) => {
    setKeywordId(event.target.id);
    setModalType("Edit");
    setShow(true);
    setDefaultKeyword(keywordName);
  };
  const deleteRow = (event) => {
    Swal.fire({
      title: "Are you sure, you want to delete this keyword?",
      // text: "But you will still be able to retrieve this file.",
      // type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#bb2d3b",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      // closeOnConfirm: false,
      // closeOnCancel: false
    }).then((result) => {
      if (result.value) {
        console.log(event.target.id, "fjjjjjjjjjjjj");
        let keywordId = event.target.id;
        fetch(
          process.env.REACT_APP_API_URL + "jobs/deletekeyword/" + keywordId,
          {
            method: "PUT",
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          }
        ).then((data) =>
          data
            .json()
            .then((result) => {
              console.log(result);
              if (result.success) {
                setIsFlashError(false);
                setFlashErrorMsg(result.message);
                fetchData();
              } else {
                setIsFlashError(true);
                setFlashErrorMsg(result.message);
              }
            })
            .catch((err) => {
              console.log(err);
            })
        );
      }
    });
  };

  const handleStatus = (e, id) => {
    var status = '1';
    if(e.target.checked === true) {
        status = '1'
    }
    else {
        status = '0'
    }
    console.log(status);
    fetch( process.env.REACT_APP_API_URL + `jobs/keywordStatus/${id}`, {
        method: "PUT",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        // data:{status:status}
        body: JSON.stringify({ status }),
      }).then((data) => data.json())
        .then((result) => {
          console.log(result);
            
        })
      .catch((err) => {
        console.log(err);
      })
    // axios.put(`/component/updateStatus/${id}`, {status}).then(res => {                
    // })
    // .catch(err => {
    // })
}
  const columns = useMemo(
    () => [
      {
        name: "Name",
        selector: (row) => row.name,
        sortable: true,
        grow: 4,
      },
      {
        name: "Status",
        selector: (row) => (
          <div>
            <input className="tgl tgl-light" id={row.id} type="checkbox" onChange={(e) => handleStatus(e, row.id)} defaultChecked={row.status === '1' ? true : false}></input>
            <label className="tgl-btn" htmlFor={row.id}></label>
          </div>
        ),
        sortable: true,
        grow: 1,
      },
      {
        name: "Action",
        cell: (row) => (
          <div className="btn-group" role="group" aria-label="Basic example">
            <button type="button" id={row.id} onClick={(e) => editRow(e, row.name)} className="btn btn-secondary ss-normal-btn"> Edit </button>
            <button type="button" id={row.id} onClick={(e) => editRow(e, row.name)} className="btn btn-secondary ss-icon-btn ss-border-radius">
              <i class="bi bi-pencil-fill"></i>
            </button>
            
            <button type="button" id={row.id} onClick={deleteRow} className="btn btn-danger ss-normal-btn ss-border-radius"> Delete </button>
            <button type="button" id={row.id} onClick={deleteRow} className="btn btn-danger ss-icon-btn">
              <i class="bi bi-trash"></i>
            </button>
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        selector: false,
      },
    ],
    []
  );

  const fetchData = () => {
    fetch(process.env.REACT_APP_API_URL + "jobs/keywords/list", {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    }).then((data) =>
      data
        .json()
        .then((result) => {
          if (result.success) {
            let res = result.data;
            // console.log(result.data);
            setRes(res);
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        })
    );
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Layout>
        <div className="row mb-3 align-items-center justify-content-center">
          <div className="col-md-4 col-6">
            <h1 className="main-heading">Manage Keywords</h1>
          </div>
          <div className="col-md-6 col-2"></div>
          <div className="col-md-2 col-4 d-grid text-right">
            <Button variant="primary" onClick={showAddModel}>
              Add
            </Button>
          </div>
        </div>
        <div
          className={
            isFlashError === true
              ? "alert alert-danger text-center removeNotification"
              : isFlashError === false
              ? "alert alert-success text-center removeNotification"
              : ""
          }
          style={{ display: isFlashError !== "" ? "block" : "none" }}
        >
          {" "}
          {isFlashError !== "" ? flashErrorMsg : ""}

          <a onClick = {() => {setIsFlashError('');}} class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
          
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card recent-sales overflow-auto">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <DataTable
                      // title="List"
                      data={res}
                      columns={columns}
                      theme="light"
                      //   selectableRows
                      pagination
                      onSelectedRowsChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <KeywordModal
          type={modalType}
          show={show}
          handleClose={handleClose}
          defaultKeyword={defaultKeyword}
          keywordId={keywordId}
          refreshKeywords={fetchData}
          isFlashError={isFlashError}
          setIsFlashError={setIsFlashError}
          flashErrorMsg={flashErrorMsg}
          setFlashErrorMsg={setFlashErrorMsg}
        ></KeywordModal>
      </Layout>
    </>
  );
}

// Add Keyword Modal
