import React, { useEffect, useRef, useState } from "react";
import { Layout } from "../Layout/Layout";

import JobTile from "../JobTile/JobTile";
export default function JobsByKeyword() {
  return (
    <>
      <Layout>
        <JobTile title="Jobs By Keyword" type="JBK" />
      </Layout>
    </>
  );
}
