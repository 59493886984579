import React, { useEffect, useRef, useState } from 'react';
import { Layout } from '../Layout/Layout';
import JobTile from '../JobTile/JobTile';
import Loader from '../Loader/Loader';

export default function Dashboard() {
    return (
        <>
            <Layout>                
                <JobTile title="Dashboard" type="0" />
            </Layout>
        </>
    )

}