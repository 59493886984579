import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Layout } from '../Layout/Layout';






const validateForm = data => {
  const errors = {};  
  console.log('error=',data);
  if (!data.name) {
    errors.name = 'Please Enter Name';
  } 
  if (!data.email) {
    errors.email = 'Please Enter Email';
  } 
  else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
    errors.email = 'Invalid email address';
  }
  
  if ( !data.mobile &&  data.mobile.length > 10 ) {
    errors.mobile = 'Too long, maximum of 10 digits required!';
  }
  else if (!data.mobile &&  data.mobile.length < 0 ) {
    errors.mobile = 'Too short, minimum of 10 digits required!';
  } 
  return errors;
};

export default function UserProfile() {

  const user = JSON.parse(localStorage.getItem('authUser'));

  const profileData = {
    profile:''
  };

  const fileInput = React.createRef();
 
  const [isProfileChanged, setIsProfileChanged] = useState('');
  const [profileChangeMsg, setProfileChangeMsg] = useState('');
  
  const [profileValue, setProfileValue] = useState('assets/img/dummy.jpg');
  const [form, setForm] = useState();

  const formik = useFormik({
      initialValues:{
        name: user.name,
        mobile: user.mobile,
        email: user.email,
        profile: user.profile_img,
    },
    validate: validateForm,
    onSubmit: values => {

      console.log('values=',values);
        updateProfile(values);           
    }
  });

  const updateProfile = (data) => {
      console.log('data',data);
      
      let formData = new FormData();      
      formData.append('data',data); 
      return fetch(process.env.REACT_APP_API_URL + 'updateprofile', {
          method: 'POST',
          headers: {
              'accept': '*/*',
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
          body: JSON.stringify(data)
      }).then(data => data.json()).then(result => {          
          setIsProfileChanged(result.success);
          setProfileChangeMsg(result.message);
          if(result.success){
            let authUserObj = JSON.parse(localStorage.getItem('authUser'));

            authUserObj.name = ( data.name ? data.name : '' );
            authUserObj.email = ( data.email ? data.email : '' );
            authUserObj.mobile = ( data.mobile ? data.mobile : '' );
            authUserObj.profile_img = ( data.profile_img ? data.profile_img : '' );

            localStorage.setItem('authUser', JSON.stringify(authUserObj));
          }

      });
  }

    return (
    <>
    <Layout>
      <section className="section profile">
        <div className='row align-items-center'>
            <div className='col-lg-3 col-md-3 col-sm-12'>
                <h1 className='main-heading'>My Profile</h1>
            </div>
            <div className='col-lg-7 col-md-8 col-sm-12'>
                <div className="search-bar">                        
                    {/* <input type="text" name="searchText" className="form-control" placeholder="What are you looking for ?" title="What are you looking for ?" onChange={ (e) => handleSearch(e.target.value)} /> */}
                </div>
            </div>                
            <div className='col-lg-1 col-md-1 col-sm-12'>
                <div className="text-right">
                    {/* <a onClick={handleJobRefresh}><AutorenewIcon /></a> */}
                </div>
            </div>
        </div>
        <div className='job-detls' style={{background: '#fff'}}>
          <div className="row">
            <div className="col-xl-10">
              <div className={isProfileChanged  ? 'alert alert-success text-center removeNotification' : 'alert alert-danger text-center removeNotification'} style={{ display: isProfileChanged !== '' ? 'block' : 'none'}}>
                {isProfileChanged !== '' ? profileChangeMsg : ''}
                <a onClick = {() => {setIsProfileChanged('');}} class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
              </div> 
              <div className="card">
                <div className="card-body pt-3">             
                  <form onSubmit={formik.handleSubmit}>
                    {/* <div className="row mb-3">
                      <label htmlFor="profileImage" className="col-md-4 col-lg-3 col-form-label">Profile Image</label>
                      <div className="col-md-8 col-lg-9">
                        <img src="assets/img/dummy.jpg" style={{width:'150px'}} alt="Profile"/>
                        <div className="pt-2">
                          <a href="#" className="btn btn-primary btn-sm mr-2" title="Upload new profile image"><i className="bi bi-upload"></i></a>
                          <input name="profile" type="file" className="form-control"  onChange={(e) => {
                                  const fileReader = new FileReader();
                                  fileReader.onload = () => {
                                    if (fileReader.readyState === 2) {
                                      formik.setFieldValue('profile', fileReader.result);
                                      setProfileValue(fileReader.result);
                                    }
                                  };
                                  fileReader.readAsDataURL(e.target.files[0]);
                                }} ref={fileInput} accept="image/*" />
                          <a href="#" className="btn btn-danger btn-sm" title="Remove my profile image"><i className="bi bi-trash"></i></a>
                        </div>
                      </div>
                    </div> */}

                      <div className="row mb-3">
                        <label htmlFor="fullName" className="col-md-4 col-lg-3 col-form-label">Full Name</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="name" type="text" className="form-control" defaultValue={formik.values.name} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                          {formik.touched.name && formik.errors.name ? <div className="error-message text-danger">{formik.errors.name}</div> : null}
                        </div>
                      </div>                   

                      <div className="row mb-3">
                        <label htmlFor="Phone" className="col-md-4 col-lg-3 col-form-label">Phone</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="mobile" type="number" className="form-control" defaultValue={formik.values.mobile} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                          {formik.touched.mobile && formik.errors.mobile ? <div className="error-message text-danger">{formik.errors.mobile}</div> : null}
                        </div>
                      </div>

                      <div className="row mb-3">
                        <label htmlFor="Email" className="col-md-4 col-lg-3 col-form-label">Email</label>
                        <div className="col-md-8 col-lg-9">
                          <input name="email" type="email" className="form-control" defaultValue={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                          {formik.touched.email && formik.errors.email ? <div className="error-message text-danger">{formik.errors.email}</div> : null}
                        </div>
                      </div>
                      <div class="text-center">
                        <button type="submit" class="btn btn-primary">Save Changes</button>
                      </div>
                    </form>   
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    </Layout>             

    </>
  );
}