import React, { useEffect, useRef, useState } from 'react';
import { Layout } from '../Layout/Layout';
import Loader from '../Loader/Loader';
import Swal from 'sweetalert2';

export default function QuestionAnswers() {
    const [isLoading, setIsLoading] = useState(false);
    const [isShowAnswers, setIsShowAnswers] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [anwers, setAnswers] = useState([]);
    const [isEndList, setIsEndList] = useState(false);
    const [pageCount, setPageCount] = useState(1);

    useEffect(() => {
        getQuestions();
    }, []);

    const getQuestions = async () => {
        if(navigator.onLine){
            if (isLoading) return;
            setIsLoading(true);
            
            fetch(process.env.REACT_APP_API_URL + 'getquestions', {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then(data => data.json().then((result) => {
                if(result.success){
                    console.log(result);
                    if(pageCount > 1)
                        setQuestions([...questions, ...result.data]);
                    else
                        setQuestions(result.data);
                               
                    setPageCount(pageCount+1); 
                    setQuestions(result.data);
                    setIsLoading(false);
                    console.log(questions);
                }
                else{
                   setIsLoading(false);
                }
            }).catch((err) => {
                console.log(err);
            }));
        }
        else{
            Swal.fire('No Network Connection.');
        }
    }
    const handleQuestionClick = (questionId) =>{
        getQuestionAnswers(questionId);
    }

    const getQuestionAnswers = (questionId) =>{
        if(navigator.onLine){
            if (isLoading) return;
            console.log(questionId);
            setIsLoading(true);
            return fetch(process.env.REACT_APP_API_URL + 'getanswersbyquestionid/?question_id='+questionId, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then(data => data.json()).then(result => {
                if(result.success){

                    console.log(result);
                    setAnswers(result.data);
                    setIsShowAnswers(true);
                    setIsLoading(false);
                }
                else{
                    setIsLoading(false);
                }
            });            
        }
        else{
            Swal.fire('No Network Connection.');
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    });

    const onScroll = () => { 
        const winHeight = window.innerHeight;
        const srcHeight = document.documentElement.scrollHeight;
        const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
        const toBottom = srcHeight - winHeight - scrollTop;
        console.log('scroll-',toBottom);
        if (toBottom <= 0.8) {
            console.log(pageCount);
            if (pageCount <= 10000){
                getQuestions();
                setIsEndList(false);
            }
            else{
                setIsEndList(true);
            }
        }
    };

    return (
        <>
            { isLoading === true ? <Loader  /> : '' }
            <Layout>   
                <section>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h1 className='main-heading'>Questions and Answers</h1>
                        </div>
                    </div>
                </section>
                <section className="section">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="">
                                <div className="">
                                    {/* <h5 className="card-title">Default Accordion</h5> */}
                                    <div className="accordion" id="accordionExample">
                                        {
                                        questions.length > 0  ?
                                        questions.map( (question) => (
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne" onClick={ () => handleQuestionClick(question.id)}>
                                                    {question.question} 
                                                    </button>
                                                </h2>
                                                <div id="collapseOne" className={`accordion-collapse collapse ${isShowAnswers ? 'show' : ''}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body">
                                                        {anwers}
                                                        {/* <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classNamees that we use to style each element. These classNamees control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow. */}
                                                    </div>
                                                </div>
                                            </div>
                                        )) : ""
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </>
    )

}