import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';

const validateForm = data => {
  const errors = {};  
  if (!data.username) {
      errors.username = 'Please Enter Email';
  } 
  return errors;
};

export default function ForgotPassword() {
  const [isError, setIsError] = useState(false);

  const [changePasswordStatus, setChangePasswordStatus] = useState('');
  const [changePasswordMsg, setChangePasswordMsg] = useState('');

  const formik = useFormik({
    initialValues:{
        username: '',
    },
    validate: validateForm,
    onSubmit: values => {
        forgotPassword(values);            
    }
  });

  const forgotPassword = (data) => {
    console.log(data);
    return fetch(process.env.REACT_APP_API_URL + 'forgotpassword', {
        method: 'POST',
        headers: {
            'accept': '*/*',
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        },
        body: JSON.stringify(data)
    }).then(data => data.json()).then(result => {
      console.log(result);
        setChangePasswordStatus(result.success);
        setChangePasswordMsg(result.message);
      
         
    });
  }

  const handleCheckCurrentEmail = (email) => {
    console.log('email',email);
    if(email.length > 5){
      return fetch(process.env.REACT_APP_API_URL + 'checkvalidateemail', {
          method: 'POST',
          headers: {
              'accept': '*/*',
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
          body: JSON.stringify({email})
      }).then(email => email.json()).then(result => {
          console.log(result);
          if (result.success) {
            setIsError(false);
          }
          else{
            setIsError(true);
          }
      });
    }
}

  return (
    <>
      <main>
      <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center bg-sky py-4">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                    <div class="d-flex justify-content-center py-4">
                        <a href="index.php" class="logo d-flex align-items-center w-auto">
                            <img src="assets/img/logo.png" alt=""/>
                        </a>
                    </div>
                    
                    

                    <div class="card mb-3">
                        <div class="card-body">
                            <div class="pt-2 pb-2">
                                <h5 class="card-title text-center pb-0 fs-4">Forgot Password</h5>
                            </div>
                            <div className={changePasswordStatus  ? 'alert alert-success text-center removeNotification' : 'alert alert-danger text-center removeNotification'} style={{ display: changePasswordStatus !== '' ? 'block' : 'none'}}>
                            {changePasswordStatus !== '' ? changePasswordMsg : ''}
              {/* <a onClick = {removeNotification} class="close" data-dismiss="alert" aria-label="close" title="close">×</a> */}
                            </div>

                            <form class="row g-3 needs-validation" onSubmit={formik.handleSubmit}>
                                <div class="col-12">
                                    <label for="" class="form-label">Email</label>
                                    <div class="input-group has-validation">
                                        <span class="input-group-text" id="inputGroupPrepend"><i class="bi bi-person"></i></span>
                                        <input type="email" name="username" class="form-control" defaultValue={formik.values.username} onChange={formik.handleChange} onBlur={formik.handleBlur} onKeyUp={(e) => handleCheckCurrentEmail(e.target.value)}/>
                                    </div>
                                    <div className="error text-danger" style={{ display: isError === true ? 'block' : 'none' }}>Email did not match.Try again</div>
                                    {formik.touched.username && formik.errors.username ? <div className="error text-danger">{formik.errors.username}</div> : null}
                                </div>                               

                                <div class="col-12 mt-4">
                                  <button type='submit' class="r-btn r-btn-primary btn-block ft-uppercase">
                                    <i class="bi bi-send ft-sm mr-2"></i> Submit
                                  </button>
                                </div>
                                <div class="col-12">
                                    <p class="small mb-2">Don't have account? <Link to="/login">Login </Link></p>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        </section>
        </main>
    </>
  );
}