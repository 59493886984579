import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import DataTableComp from "../../Common/Datatable";
export default function ViewedByClientJobsPopup({show, setShow, selectedDate,  ...rest}) {
    const [viewedByClientJobs, setViewedByClientJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setShow(false);
        setViewedByClientJobs([]);
    };

    useEffect(() => {
        if(show === true)
            getViewedByClientJobsList();
    }, [show, selectedDate])

    const getViewedByClientJobsList = async () => {
        setLoading(true)
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `viewedByClientJobsbyDays?date=${selectedDate}`);
            setLoading(false)
            if (response.data.success) {
                setViewedByClientJobs(response.data.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching Viewed By client Jobs list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    const handlePopupClose = () => {
        handleClose();
        setViewedByClientJobs([]);
    }

    const columns = [
        {
            name: 'S No.',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '18%'
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,    
            cell: row => 
                <Link to={"https://upwork.com/jobs/" + row.job_id} className="job-icon attachment" target="_Blank" style={{fontWeight: 'bold'}}>
                    {row.title}
                </Link>        
            // width: '16%'
        }
    ];

    return(
        <Modal show={show} onHide={handlePopupClose} size={'lg'}>
            <Modal.Header closeButton>
            <Modal.Title>Viewed By Client Jobs</Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                {loading=== true ? 
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    : 
                    <div className="row">
                        <DataTableComp columns={columns} data={viewedByClientJobs}/>
                    </div>
                }
                    
                </Modal.Body>
            </form>
            <Modal.Footer>
                <button type="submit" className="btn btn-primary" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    ); 
}