import { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import DataTableComp from "../../Common/Datatable";

export default function BoostedJobsPopup({show, setShow, selectedDate,  ...rest}) { 
    const [boostedJobs, setBoostedJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleClose = () => {
        setShow(false);
        setBoostedJobs([]);
    };

    useEffect(() => {
        if(show === true)
            getBoostedJobsList();
    }, [show, selectedDate])

    const getBoostedJobsList = async () => {
        setLoading(true)
        try {
            const response = await axios.get(process.env.REACT_APP_API_URL + `boostedJobsbyDays?date=${selectedDate}`);
            setLoading(false)
            if (response.data.success) {
                setBoostedJobs(response.data.data);
                console.log("response.data.data", response.data.data.length)
            } else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching Boosted Jobs list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    const handlePopupClose = () => {
        handleClose();
        setBoostedJobs([]);
    }

    const columns = [
        {
            name: 'S No.',
            selector: (row, index) => index + 1,
            sortable: true,
            width: '11%'
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true,    
            cell: row => 
                <Link to={"https://upwork.com/jobs/" + row.job_id} className="job-icon attachment" target="_Blank" style={{fontWeight: 'bold'}}>
                    {row.title}
                </Link>        
            // width: '16%'
        },
        {
            name: 'Viewed',
            selector: row => row.is_viewed_by_client,
            sortable: true,
            cell: row => (
                <div className="d-flex align-items-center">
                    <div className={ row.is_viewed_by_client === '1'  ? "text-success" : "text-danger"}>
                        {row.is_viewed_by_client === '1' 
                            ? <Chip label="Yes" size="small" className="bg-success-light" style={{color: "black", fontWeight: 'bold'}}/>
                            : <Chip label="No" size="small" className="bg-warning-light" style={{color: "black", fontWeight: 'bold'}}/>
                        }
                    </div>
                </div>
            ),
            width: '15%', 
        }
    ];

    return(
        <Modal show={show} onHide={handlePopupClose} size={'lg'}>
            <Modal.Header closeButton>
            <Modal.Title>Boosted Jobs</Modal.Title>
            </Modal.Header>
            <form>
                <Modal.Body>
                {loading=== true ? 
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                : 
                    <div className="row">
                        <DataTableComp columns={columns} data={boostedJobs}/>
                    </div>
                }
                </Modal.Body>
            </form>
            <Modal.Footer>
                <button type="submit" className="btn btn-primary" onClick={handleClose}>Close</button>
            </Modal.Footer>
        </Modal>
    ); 
}