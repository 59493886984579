import React, { useEffect, useState } from 'react';
import { Layout } from '../Layout/Layout';
import Loader from '../Loader/Loader';
import Swal from 'sweetalert2';
import BoostedJobsPopup from './Popup/BoostedJobsPopup';
import ViewedByClientJobsPopup from './Popup/ViewedByClientJobsPopup';

export default function Report() {
    const [isLoading, setIsLoading] = useState(false);
    const [reportByDays, setReportByDays] = useState([]);
    const [reportByWeeks, setReportByWeeks] = useState([]);
    const [reportByMonths, setReportByMonths] = useState([]);
    const [filterCategory, setFilterCategory] = useState('All'); 
    
    const [isShowDaysReport, setIsShowDaysReport] = useState(false); 
    const [isShowWeekReport, setIsShowWeekReport] = useState(false); 
    const [isShowMonthReport, setIsShowMonthReport] = useState(false); 
    const [showBoostedPopup , setShowBoostedPopup ] = useState(false);
    const [selectedBoostedDate , setSelectedBoostedDate ] = useState(null);

    const [showViewedByClientPopup , setShowViewedByClientPopup ] = useState(false);
    const [selectedViewedByClientDate , setViewedByClientDate ] = useState(null);


    useEffect(() => {
        getReportByDays();
        getReportByWeeks();
        getReportByMonths();
    }, [filterCategory]);
      
    const getReportByDays = async () => {
        if(navigator.onLine){            
            fetch(process.env.REACT_APP_API_URL + 'reportByDays?categoryFilter='+filterCategory, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then(data => data.json().then((result) => {
                if(result.success){
                    setReportByDays(result.data);
                    console.log("reportByDays-----------------", result.data);
                    setIsShowDaysReport(true);
                }
                else{
                    setIsShowDaysReport(true);
                }
            }).catch((err) => {
                console.log(err);
            }));
        }
        else{
            Swal.fire('No Network Connection.');
        }
    }
    const getReportByWeeks = async () => {
        if(navigator.onLine){            
            fetch(process.env.REACT_APP_API_URL + 'reportByWeeks?categoryFilter='+filterCategory, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then(data => data.json().then((result) => {
                if(result.success){
                    setReportByWeeks(result.data);
                    console.log(reportByDays);
                    setIsShowWeekReport(true);
                }
                else{
                    setIsShowWeekReport(true);
                }
            }).catch((err) => {
                console.log(err);
            }));
        }
        else{
            Swal.fire('No Network Connection.');
        }
    }
    const getReportByMonths = async () => {
        if(navigator.onLine){            
            fetch(process.env.REACT_APP_API_URL + 'reportByMonths?categoryFilter='+filterCategory, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then(data => data.json().then((result) => {
                if(result.success){
                    setReportByMonths(result.data);
                    console.log(reportByDays);
                    setIsShowMonthReport(true);
                }
                else{
                    setIsShowMonthReport(true);
                }
            }).catch((err) => {
                console.log(err);
            }));
        }
        else{
            Swal.fire('No Network Connection.');
        }
    }

    const handleFilter = (category) => {
        setFilterCategory(category);
        setIsShowDaysReport(false);
        setIsShowWeekReport(false);
        setIsShowMonthReport(false);
    }

    const handleBoostedJobPopup = (date) => {
        setSelectedBoostedDate(date);
        setShowBoostedPopup(true);
    }

    const handleViewedByClientJobPopup = (date) => {
        setViewedByClientDate(date);
        setShowViewedByClientPopup(true);
    }
    return (
        <>
            {/* { isLoading === true ? <Loader  /> : '' } */}
            <Layout>                
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-4 col-4'>
                        <h1 className='main-heading'>Reports</h1>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-2 col-2'>
                        <div className="text-right">
                            {/* <select onChange={ (e) => handleFilter(e.target.value)}>
                                <option value="All">All</option>
                                <option value="Accounting & Consulting">Accounting & Consulting</option>
                                <option value="Data Science & Analytics">Data Science & Analytics</option>
                                <option value="IT & Networking">IT & Networking</option>
                                <option value="Web, Mobile & Software Dev">Web, Mobile & Software Dev</option>
                            </select> */}
                        </div>
                    </div>
                    <div className='col-lg-2 col-md-2 col-sm-6 col-6'>
                        <div className="text-right mb-2">
                            <select className='form-control ss-font'>
                                <option>Table View</option>
                                <option>Chart View</option>
                            </select>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="box table-responsive">
                                <h5>Last 30 Days</h5>                  
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Fetch</th>
                                            <th>Accepted</th>
                                            <th>Rejected</th>
                                            <th>Approved</th>
                                            <th>Bid</th>
                                            <th>Responded</th>
                                            <th>Boosted</th>
                                            <th>Viewed by Client</th>
                                            <th>Total jobs</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {   
                                        isShowDaysReport ? 
                                            reportByDays.length > 0 ? 
                                            reportByDays.map( (day) => (
                                                <tr>                                            
                                                    <td>{day.date}</td>
                                                    <td>{day.fetch_jobs}</td>
                                                    <td>{day.accepted + day.bid}</td>
                                                    <td>{day.rejected}</td>
                                                    <td>{day.approved + day.bid}</td>
                                                    <td>{day.bid}</td>
                                                    <td>{day.responded}</td>
                                                    <td onClick={() => handleBoostedJobPopup(day.date)} style={{cursor:'pointer'}}>{day.is_job_boosted}</td>
                                                    <td onClick={() => handleViewedByClientJobPopup(day.date)} style={{cursor:'pointer'}}>{day.is_viewed_by_client}</td>
                                                    <td>{day.total_jobs}</td>
                                                </tr>
                                            )) : 
                                            <div className='alert alert-danger' style={{display: reportByDays.length > 0 ? 'none': 'block' }}>No Record Found !</div>
                                        :
                                        <tr><td>Loading...</td></tr>
                                    }                                        
                                    </tbody>
                                </table>
                            </div>                        
                        </div>
                        {/* <div className="col-md-4">
                            <div className="box">
                                <h5>Last 15 Weeks</h5>                                
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Week</th>
                                            <th>Fetch</th>
                                            <th>Accepted</th>
                                            <th>Rejected</th>
                                            <th>Approved</th>
                                            <th>Bid</th>
                                            <th>Responded</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            isShowWeekReport ? 
                                                reportByWeeks.length > 0 ? 
                                                reportByWeeks.map( (day) => (
                                                    <tr>                                            
                                                        <td>{day.start_date+"-"+day.end_date}</td>
                                                        <td>{day.fetch_jobs}</td>
                                                        <td>{day.accepted + day.bid}</td>
                                                        <td>{day.rejected}</td>
                                                        <td>{day.approved + day.bid}</td>
                                                        <td>{day.bid}</td>
                                                        <td>{day.responded}</td>
                                                    </tr>
                                                )) : 
                                                <div className='alert alert-danger' style={{display: reportByWeeks.length > 0 ? 'none': 'block' }}>No Record Found !</div>
                                            :
                                            <tr><td>Loading...</td></tr>
                                    }
                                    </tbody>
                                </table>
                            </div>                        
                        </div> */}
                        <div className="col-md-6">
                            <div className="box table-responsive">  
                                <h5>Last 12 Months</h5>                               
                                <table className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>Month</th>
                                            <th>Fetch</th>
                                            <th>Accepted</th>
                                            <th>Rejected</th>
                                            <th>Approved</th>
                                            <th>Bid</th>
                                            <th>Responded</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                       { 
                                        isShowMonthReport ? 
                                            reportByMonths.length > 0 ? 
                                            reportByMonths.map( (day) => (
                                                <tr>                                            
                                                    <td>{day.date}</td>
                                                    <td>{day.fetch_jobs}</td>
                                                    <td>{day.accepted + day.bid}</td>
                                                    <td>{day.rejected}</td>
                                                    <td>{day.approved + day.bid}</td>
                                                    <td>{day.bid}</td>
                                                    <td>{day.responded}</td>
                                                </tr>
                                            )) : 
                                            <div className='alert alert-danger' style={{display: reportByMonths.length > 0 ? 'none': 'block' }}>No Record Found !</div>
                                            :
                                            <tr><td>Loading...</td></tr>
                                    }
                                    </tbody>
                                </table>
                            </div>                        
                        </div>
                    </div>
                    <BoostedJobsPopup
                        show={showBoostedPopup}
                        setShow={setShowBoostedPopup}
                        selectedDate={selectedBoostedDate}
                    />
                    <ViewedByClientJobsPopup
                        show={showViewedByClientPopup}
                        setShow={setShowViewedByClientPopup}
                        selectedDate={selectedViewedByClientDate}
                    />
                </section>
            </Layout>
        </>
    )

}