import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../Layout/Layout';

const validateForm = data => {
  const errors = {};  
  console.log(data);
  if (!data.old_password) {
      errors.old_password = 'Please Enter Current Password';
  } 

  if (!data.password) {
      errors.password = 'Please Enter New Password';
  }
  if (data.password.length < 6) {
    errors.password = 'Too Short, require atleast 6 characters';
  }

  if (!data.confirm_password) {
      errors.confirm_password = 'Please Enter Confirm Password';
  }

  if (data.confirm_password !== data.password) {
      errors.confirm_password = 'New Password and Confirm Password does not match';
  }

  return errors;
};

export default function ChangePassword() {

  const navigate = useNavigate();
    
  const [isError, setIsError] = useState(false);
  const [changePasswordStatus, setChangePasswordStatus] = useState('');
  const [changePasswordMsg, setChangePasswordMsg] = useState('');

  const formik = useFormik({
      initialValues:{
          old_password: '',
          password: '',
          confirm_password: '',
      },
      validate: validateForm,
      onSubmit: values => {
          changePassword(values);  
          formik.resetForm();          
      }
  });

  const changePassword = (data) => {
      console.log(data);
      return fetch(process.env.REACT_APP_API_URL + 'changepassword', {
          method: 'POST',
          headers: {
              'accept': '*/*',
              'Content-Type': 'application/json',
              'token': localStorage.getItem('token')
          },
          body: JSON.stringify(data)
      }).then(data => data.json()).then(result => {
          console.log(result);
          // if (result.success) {
              setChangePasswordStatus(result.success);
              setChangePasswordMsg(result.message);
          // }
          // else {
          //   setChangePasswordStatus(false);
          //   setChangePasswordMsg(result.message);
          // }
      });
  }

  const handleCheckCurrentPassword = (password) => {
      console.log('password',password);
      if(password.length > 3){

        return fetch(process.env.REACT_APP_API_URL + 'checkvalidatepassword', {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify({password})
        }).then(password => password.json()).then(result => {
            console.log(result);
            if (result.success) {
                console.log(result.message);
                setIsError(false);
            }
            else {
                console.log(result);
                setIsError(true);
            }
        });
      }
  }

  // const removeNotification = (e) => {
  //   const element  = document.getElementsByClassName("removeNotification")
  //   while(element.length > 0){
  //       element[0].parentNode.removeChild(element[0]);
  //   }
  // };

  return (
    <>
    <Layout>      
        <section class="section profile">
            <div className='row align-items-center'>
                <div className='col-lg-3 col-md-3 col-sm-12'>
                    <h1 className='main-heading'>Change Password</h1>
                </div>
                <div className='col-lg-7 col-md-8 col-sm-12'>
                    <div className="search-bar">                        
                        {/* <input type="text" name="searchText" className="form-control" placeholder="What are you looking for ?" title="What are you looking for ?" onChange={ (e) => handleSearch(e.target.value)} /> */}
                    </div>
                </div>                
                <div className='col-lg-1 col-md-1 col-sm-12'>
                    <div className="text-right">
                        {/* <a onClick={handleJobRefresh}><AutorenewIcon /></a> */}
                    </div>
                </div>
            </div>
            <div className='job-detls' style={{background: '#fff'}}>
                <div class="row">
                    <div class="col-xl-10 ">
                        <div className={changePasswordStatus  ? 'alert alert-success text-center removeNotification' : 'alert alert-danger text-center removeNotification'} style={{ display: changePasswordStatus !== '' ? 'block' : 'none'}}>
                            {changePasswordStatus !== '' ? changePasswordMsg : ''}
                            <a onClick =  {() => {setChangePasswordStatus('');}} class="close" data-dismiss="alert" aria-label="close" title="close">×</a>
                        </div> 
                        <div class="card">
                            <div class="card-body pt-3">           
                            <form onSubmit={formik.handleSubmit} >
                                <div class="row mb-3">
                                    <label for="currentPassword" class="col-md-4 col-lg-3 col-form-label">Current Password</label>
                                    <div class="col-md-8 col-lg-9">
                                        <input {...formik.getFieldProps('old_password')} type="password" name="old_password" className="form-control" defaultValue={formik.values.old_password} onChange={formik.handleChange} onBlur={formik.handleBlur} onKeyUp={(e) => handleCheckCurrentPassword(e.target.value)}/>
                                        {formik.touched.old_password && formik.errors.old_password ? <div className="error-message text-danger">{formik.errors.old_password}</div> : null}

                                        <div className="error-message text-danger" style={{ display: isError === true ? 'block' : 'none' }}>Current Password is wrong!</div>
                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="newPassword" class="col-md-4 col-lg-3 col-form-label">New Password</label>
                                    <div class="col-md-8 col-lg-9">
                                        <input {...formik.getFieldProps('password')} type="password"  name="password" className="form-control" defaultValue={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                        {formik.touched.password && formik.errors.password ? <div className="error-message text-danger">{formik.errors.password}</div> : null}

                                    </div>
                                </div>

                                <div class="row mb-3">
                                    <label for="renewPassword" class="col-md-4 col-lg-3 col-form-label">Confirm Password</label>
                                    <div class="col-md-8 col-lg-9">
                                        <input {...formik.getFieldProps('confirm_password')} type="password" name="confirm_password" className="form-control" defaultValue={formik.values.confirm_password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>
                                        {formik.touched.confirm_password && formik.errors.confirm_password ? <div className="error-message text-danger">{formik.errors.confirm_password}</div> : null}

                                    </div>                                
                                </div>
                                <div class="text-center">
                                    <button type="submit" class="btn btn-primary">Change Password</button>
                                </div>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
    </Layout>             

    </>
  );
}