import React, { useState } from 'react';
import { Layout } from '../Layout/Layout';
import JobTile from '../JobTile/JobTile';
import Loader from '../Loader/Loader';

export default function JobRejected(){
    // const [isLoading, setIsLoading] = useState(false);
    return(
        <>
        {/* { isLoading === true ? <Loader  /> : '' } */}
        <Layout>
            <JobTile title="Rejected Jobs" type="2" />
        </Layout>
        </>
    )
}