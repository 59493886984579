
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';

const validationSchema = yup.object({
    prompt: yup
    .string()
    .required('This field is required!')
}); 
export default function AddPrompt({promptId, setPromptId, getPromptsList}) {
    useEffect(() => {
        if(promptId !== ''){
            getList();
            formik.setFieldValue('promptId',promptId)
        }
        else{
            formik.setFieldValue('promptId','')
            formik.setFieldValue('prompt','')
        }
      }, [promptId]);
    
      const getList = async() => {
        console.log("promptId",promptId)
        if(promptId){
            return fetch(process.env.REACT_APP_API_URL + `getPrompt?id=${promptId}`, {
                method: 'GET',
                headers: {
                    'accept': '*/*',
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                },
            }).then((data) => data.json().then((response) => {
                console.log("response0000----------------------------",response);
                if(response.success){
                    promptId === '' ? setPromptId(response.data.id) : setPromptId(promptId)
                    promptId === '' ? formik.setFieldValue('promptId',response.data.id) : formik.setFieldValue('promptId',promptId)
                    formik.setFieldValue('prompt',response.data.prompt)
                }
                else {
                    console.log("error");
                }
            }).catch((err) => {
                console.log(err);
            })
            );
        }
        else{
            Swal.fire("Edit Error!")
        }
    }

    const initialValues = {
        promptId: promptId,
        prompt: '',
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSave(values);
        }
    });

    const handleSave = (postData) =>{
        console.log("postData",postData)
        // return;
        return fetch(process.env.REACT_APP_API_URL + 'AddPrompt', {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify(postData)
        }).then(data => data.json()).then(res => {
            if(res.success){
                // setShow(false);
                Swal.fire(res.message, '', 'success').then(result => {                    
                    getPromptsList()
                })                
            } 
            else{
                Swal.fire(res.message, '', 'error')
            }
        })
        .catch((error) => {
            Swal.fire(error, '', 'error')
        });    
    }

    return (     
        <form onSubmit={formik.handleSubmit}>
            {console.log("errorrr",formik.errors)}
            <textarea name="prompt" className={'form-control h-100 mb-3'} rows="15" cols="50" onChange={formik.handleChange} value={formik.values.prompt}></textarea>   
            {formik.touched.prompt && formik.errors.prompt ? <div className="text-danger">{formik.errors.prompt}</div> : null}
            <div className="text-right">
                <button type="submit" className="r-btn r-btn-primary">{promptId === '' ? "Add" : "Update"}</button>
            </div>  
        </form>
    );
}