import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PersonIcon from '@mui/icons-material/Person';
import SettingsIcon from '@mui/icons-material/Settings';
import LockOpenIcon from '@mui/icons-material/LockOpen';


export default function Header() {
    const location = useLocation();
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('authUser'));
    const [isToggleSidebarActive, setIsToggleSidebarActive] = useState(false);
    const [isSettingDropdownActive, setIsSettingDropdownActive] = useState(false);

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    }

    const handleToggleSidebar = () => {
        if(isToggleSidebarActive === false){
            document.body.classList.add('toggle-sidebar');
            setIsToggleSidebarActive(true);
        }
        else{
            document.body.classList.remove('toggle-sidebar');
            setIsToggleSidebarActive(false);
        }
    }
    const routeRefresh = () => {
        navigate(0);
    }

    const handleSettingDropdown = () => {
        if(isSettingDropdownActive === false){
            setIsSettingDropdownActive(true);
        }
        else{
            setIsSettingDropdownActive(false);
        }
    }
    return (
        <>
            <header id="header" className="header fixed-top d-flex align-items-center">
                <div className="d-flex align-items-center justify-content-between">
                    <Link to="/dashboard" className="logo d-flex align-items-center">
                        <img src="assets/img/logo.png" alt="Logo" />
                    </Link>
                    <i className="bi bi-list toggle-sidebar-btn" onClick={handleToggleSidebar}></i>
                </div>


                <nav className="header-nav ms-auto">
        <ul className="d-flex align-items-center">        

            

            <li className="nav-item dropdown pe-3">

                <div className="nav-link nav-profile d-flex align-items-center pe-0 show" data-bs-toggle="dropdown" aria-expanded="true" onClick={handleSettingDropdown} >
                    <img style={{display: user.id === 1 ? 'inline-block' : 'none' }} src="assets/img/Sumit_Sir.jpg" alt="Profile" className="rounded-circle" />
                    <img style={{display: user.id === 3 ? 'inline-block' : 'none' }} src="assets/img/Himani_Mam.jpg" alt="Profile" className="rounded-circle" />
                    <img style={{display: user.id === 6 ? 'inline-block' : 'none' }} src="assets/img/pinkey_yadav.jpg" alt="Profile" className="rounded-circle" />
                    <img style={{display: user.id === 7 ? 'inline-block' : 'none' }} src="assets/img/deepika_rai.jpg" alt="Profile" className="rounded-circle" />
                    <span className="d-none d-md-block dropdown-toggle ps-2  " onClick={handleSettingDropdown} >{user.name}</span>
                </div>

                <ul className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow profile rj-dropdow formobile ${isSettingDropdownActive ? 'show' : '' }`}  data-popper-placement="bottom-end"> 
                    <li>                       
                        <Link className="dropdown-item d-flex align-items-center" onClick={location.pathname === '/>user_profile' ? routeRefresh : ''} to="/user_profile">
                        <PersonIcon/>
                        <span className='ml-2'>My Profile</span>
                    </Link>
                    </li>
                    <li>
                        <hr className="dropdown-divider"/>
                    </li>
                    <li>                       
                        <Link className="dropdown-item d-flex align-items-center" onClick={location.pathname === '/>change_password' ? routeRefresh : ''} to="/change_password">
                       <LockOpenIcon/>
                       <span className='ml-2'>Change Password</span>
                    </Link>
                    </li>
                     <li>
                        <hr className="dropdown-divider"/>
                    </li>               
                   <li>
                        <a className="dropdown-item d-flex align-items-center" onClick={handleLogout}>
                            <PowerSettingsNewIcon/>
                            <span className='ml-2'>Sign Out</span>
                        </a>
                    </li>

                </ul>
            </li>

        </ul>
    </nav>

                {/* <nav className="header-nav ms-auto">
                    <ul className="d-flex align-items-center">

                        <li className="nav-item">
                        <a className="nav-link" onClick={handleLogout}>
                            <PowerSettingsNewIcon />
                        </a>
                    </li>

                        <li className="nav-item pe-3">
                            <span className="nav-link nav-profile d-flex align-items-center pe-0">
                                <a className="nav-link" onClick={handleLogout}>
                                    <PowerSettingsNewIcon />
                                </a>
                                <img style={{display: user.id === 1 ? 'inline-block' : 'none' }} src="assets/img/Sumit_Sir.jpg" alt="Profile" className="rounded-circle" />
                                <img style={{display: user.id === 3 ? 'inline-block' : 'none' }} src="assets/img/Himani_Mam.jpg" alt="Profile" className="rounded-circle" />
                                <img style={{display: user.id === 6 ? 'inline-block' : 'none' }} src="assets/img/pinkey_yadav.jpg" alt="Profile" className="rounded-circle" />
                                <img style={{display: user.id === 7 ? 'inline-block' : 'none' }} src="assets/img/deepika_rai.jpg" alt="Profile" className="rounded-circle" />
                                <span className="d-none d-md-block ps-2 mt-1 ml-1">{user.name}</span>
                            </span>
                        </li>

                    </ul>
                </nav> */}
            </header>
        </>
    )
}