import React from "react";
import PropTypes from "prop-types";
export const Pagination = ({limit, setLimit, pageCount, setPageCount,  totals, ...rest}) => {
    
return(
    <>
        <div className="row align-items-center justify-content-between">
            {/* <div className="col-md-2">
                <p className="para-spacing pagination-font">Total: {totals.totalJobs}</p>
            </div> */}
            <div className="col-lg-4 col-md-5 col-12">
                <div className="d-flex align-items-center justify-content-center mb-3">
                    <div className="col-5 ps-0">
                        <div className="input-group d-flex text-right">
                            <p className="pagination-font para-spacing pe-2">Show</p>
                            <select className="form-control form-select pagination-font ss-select-input" id="inputGroupSelect03" aria-label="Example select with button addon" onChange={(e) => {setLimit(e.target.value);setPageCount(1)}} value={limit}>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="250">250</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-7 px-2">
                        <p className="para-spacing pagination-font">Total: {totals.totalJobs}</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-8 col-md-7 col-12">
                <div className="ss-d-flex mb-3">
                    <div className="d-flex align-items-center justify-content-between">
                        <p className="pagination-font para-spacing ms-auto pe-2 ss-text-center">Page {pageCount} of {totals.totalPages}</p>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination my-0">
                                <li className="page-item"><button type="button" className="page-link pagination-font" disabled={pageCount===1 ? true : false} onClick={() => setPageCount(1)}>First</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" disabled={pageCount===1 ? true : false} onClick={() => setPageCount(pageCount - 1)}>Previous</button></li>

                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: totals.totalPages > 5 && totals.totalPages - pageCount === 0 ? "block" : "none" }} onClick={() => setPageCount(pageCount-4)}>{pageCount-4}</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: totals.totalPages > 4 && totals.totalPages - pageCount === 0 ? "block" : "none" }} onClick={() => setPageCount(pageCount-3)}>{pageCount-3}</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: pageCount-2 < 1 ? "none" : "block" }} onClick={() => setPageCount(pageCount-2)}>{pageCount-2}</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: pageCount-1 < 1 ? "none" : "block" }} onClick={() => setPageCount(pageCount-1)}>{pageCount-1}</button></li>

                                <li className="page-item active"><button type="button" className="page-link pagination-font">{pageCount}</button></li>

                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: totals.totalPages > 1 && totals.totalPages - pageCount >= 1 ? "block" : "none" }} onClick={() => setPageCount(pageCount+1)}>{pageCount+1}</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: totals.totalPages > 2 && totals.totalPages - pageCount >= 2 ? "block" : "none" }} onClick={() => setPageCount(pageCount+2)}>{pageCount+2}</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: totals.totalPages > 3 && pageCount-1 <= 1 && pageCount-2 <= 1 ? "block" : "none" }} onClick={() => setPageCount(pageCount+3)}>{pageCount+3}</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" style={{ display: totals.totalPages > 4 && pageCount-1 < 1 && pageCount-2 < 1  ? "block" : "none" }} onClick={() => setPageCount(pageCount+4)}>{pageCount+4}</button></li>

                                <li className="page-item"><button type="button" className="page-link pagination-font" disabled={pageCount === totals.totalPages ? true : false} onClick={() => setPageCount(pageCount + 1)}>Next</button></li>
                                <li className="page-item"><button type="button" className="page-link pagination-font" disabled={pageCount === totals.totalPages ? true : false} onClick={() => setPageCount(totals.totalPages)} >Last</button></li>
                            </ul>
                        </nav>
                </div>
                </div>
            </div>
          </div>
    </>
)
};

export default Pagination;

Pagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    totals: PropTypes.object.isRequired,
};