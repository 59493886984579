import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";

const validateForm = data => {
    const errors = {};  
    console.log(data);
    if (!data.username) {
        errors.username = 'Please Enter User Name';
    } 
  
    if (!data.password) {
        errors.password = 'Please Enter Password';
    }
  
    return errors;
};



export default function Login() {
    const navigate = useNavigate();
    
    const [isError, setIsError] = useState(false);
    const formik = useFormik({
        initialValues:{
            username: '',
            password: '',
        },
        validate: validateForm,
        onSubmit: values => {
            login(values);            
        }
    });

    const login = (data) => {
        // console.log(data);
        return fetch(process.env.REACT_APP_API_URL + 'login', {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(data => data.json()).then(result => {
            console.log(result);
            if (result.success) {
                // console.log(result.data);
                setIsError(false);
                localStorage.setItem('authUser', JSON.stringify(result.data));
                localStorage.setItem('token', result.data.token);
                localStorage.setItem('role', result.data.role);
                localStorage.setItem('credit_limit', result.data.credit_limit);
                navigate('/dashboard');
            }
            else {
                console.log("result==",result);
                setIsError(true);
                // Swal.fire(result.message);
            }
        })
        .catch(error => {
            if (error instanceof TypeError && error.message.includes('Failed to fetch')) {
              Swal.fire('Server error: Failed to fetch');
            } else {
              Swal.fire('There was a problem with the Fetch operation:'+error);
            }
        });
    }

    

    return (
    <>
    <main>
        <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center bg-img bg-sky py-4">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-6 d-flex flex-column align-items-center justify-content-center">

                        <div className="d-flex justify-content-center py-4">
                            <a className="logo d-flex align-items-center w-auto">
                                <img src="assets/img/logo.png" alt="" />
                            </a>
                        </div>

                        <div className="card mb-3">

                            <div className="card-body">

                                <div className="pt-2 pb-2">
                                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                    <p className="text-center small">Enter your username & password to login</p>
                                </div>

                                <form onSubmit={formik.handleSubmit} className="row g-3 needs-validation">
                                    <div className="alert alert-danger text-center ft-xs" style={{ display: isError === true ? 'block' : 'none' }}>User name or password invalid</div>
                                    <div className="col-12">
                                        <label htmlFor="" className="form-label">Username or Email</label>
                                        <div className="input-group has-validation">
                                            <span className="input-group-text"><i className="bi bi-person"></i></span>
                                            <input type="text" name="username" className="form-control" defaultValue={formik.values.username} onChange={formik.handleChange} onBlur={formik.handleBlur}/>                                            
                                        </div>
                                        {formik.touched.username && formik.errors.username ? <div className="alert alert-danger text-center ft-xs">{formik.errors.username}</div> : null}
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="yourPassword" className="form-label">Password</label>
                                        <div className="input-group has-validation">
                                            <span className="input-group-text"><i className="bi bi-key"></i></span>
                                            <input type="password" name="password" className="form-control" defaultValue={formik.values.password} onChange={formik.handleChange} onBlur={formik.handleBlur}/>                                            
                                        </div>
                                        {formik.touched.password && formik.errors.password ? <div className="alert alert-danger text-center ft-xs">{formik.errors.password}</div> : null}                                  
                                    </div>

                                    <div className="col-6">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="remember" defaultValue="true" id="rememberMe" />
                                            <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="small text-right mt-1"><Link to="/forgot_password">Forgot Password ?</Link></p>
                                    </div>
                                    <div className="col-12">
                                        <button type='submit' className="r-btn r-btn-primary btn-block ft-uppercase">
                                            <i className="bi bi-box-arrow-in-right ft-sm mr-2"></i> Login
                                        </button>
                                     </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
    </>
  );
}