import React, { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from '../Layout/Layout';
import { Add } from './Add';
import AddPrompt from './AddPrompt';
import Swal from 'sweetalert2';
import axios from "axios";

export default function Setting() {

    const navigate = useNavigate();

    const [isError, setIsError] = useState(false); 
    const [emailList,setEmailList] = useState([]);
    const [popupType, setPopupType] = useState('Add');
    const [show, setShow] = useState(false); 
    const handleClose = () => setShow(false);
    const [promptsList,setPromptsList] = useState([]);
    
    const [promptId, setPromptId] = useState('');

    useEffect(() => {
        getList();
        getPromptsList();
    }, []);

    const getList = async() => {
        return fetch(process.env.REACT_APP_API_URL + 'notificationemaillist', {
            method: 'GET',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
        }).then(data => data.json()).then(response => {
            // console.log("response",response);
            if(response.success){
                setEmailList(response.data)
            }
            else {
                setIsError(true);
            }
        })
    }
    const handleAdd = () => {
        setPopupType('Add');
        setShow(true);
    }
    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                return fetch(process.env.REACT_APP_API_URL + `deletenotificationemail?id=${id}`, {
                    method: 'DELETE',
                    headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    },
                }).then(data => data.json()).then(res => {
                    if(res.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setEmailList(emailList.filter((v, i) => v.id !== id));
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }
    const handleStatus = (e, id) => {       
        var status = '1';
        if(e.target.checked === true) {
            status = '1'
        }
        else {
            status = '0'
        }
        axios.put(`api/seeks/updateStatus?id=${id}`, {status}).then(res => {                
        })
        .catch(err => {
        })
    }

    const getPromptsList = async() => {
        // return fetch(process.env.REACT_APP_API_URL + 'getPromptsList?status=0', {
            return fetch(process.env.REACT_APP_API_URL + 'getPromptsList', {
            method: 'GET',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
        }).then(data => data.json()).then(response => {
            console.log("response",response);
            if(response.success){
                setPromptsList(response.data)
            }
            else {
                setIsError(true);
            }
        })
    }
    const handleDeletePrompt = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                return fetch(process.env.REACT_APP_API_URL + `deletePrompt?id=${id}`, {
                    method: 'DELETE',
                    headers: {
                        'accept': '*/*',
                        'Content-Type': 'application/json',
                        'token': localStorage.getItem('token')
                    },
                }).then(data => data.json()).then(res => {
                    if(res.success){                             
                        Swal.fire('Deleted!', '', 'success')           
                        setPromptsList(promptsList.filter((v, i) => v.id !== id));
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }
    const handleEditPrompt = (promptId) => {
        setPromptId(promptId)
    }

    const handlePromptStatus = (e, id) => {       
        var status = e.target.checked === true ? '1':'0'
        axios.put(`${process.env.REACT_APP_API_URL}updatePromptStatus?id=${id}`, {status}).then(res => { 
            setPromptsList(
                promptsList.map((prompt) =>
                  prompt.id === id
                    ? { ...prompt, status: status }
                    : { ...prompt }
                ) );          
        })
        .catch(err => {
            console.log("prompt status update error:",err)
        })
    }
  return (
    <>
    <Layout>      
        <section className="section">
            <div className="row align-items-center justify-content-center mb-2">
                <div className="col-3">
                    <h1 className="main-heading">Notification Emails</h1>
                </div>
                <div className="col-3 text-right">
                    <button type="button" className="r-btn r-btn-primary mt-1 ss-font" onClick={()=>handleAdd()}>
                        <i className="fa fa-plus me-2"></i><span>Add Email</span>
                    </button>
                </div>
                <div className="col-3">
                    <h1 className="main-heading">{promptId !== '' ? "Edit" : "Add"} Prompt</h1>
                </div>
                <div className="col-3 text-right">
                    {promptId !== '' ?
                    <button type="button" className="r-btn r-btn-primary mt-1 ss-font" onClick={()=>setPromptId('')}>
                        <i className="fa fa-refresh me-2"></i>
                        <span>Reset</span>
                    </button>
                    :""}
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="box table-responsive">
                        {/* <h5>Emails</h5> */}
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { emailList && emailList.length > 0 ? 
                                    emailList.map( (list, index) => {
                                        return (
                                            <tr key={"email_list"+index}>
                                                <td>{list.email}</td>
                                                <td>
                                                    <a href="#" className="icon icon-sm icon-primary ml-1"  onClick={() => handleDelete(list.id)}>
                                                        <i className="fa fa-times" data-toggle="tooltip" data-placement="right" title="" data-original-title="Delete"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :<tr key={"email_list"}><td colSpan='2'>No Data Found!</td></tr>
                                }                                      
                            </tbody>
                        </table>
                    </div>                        
                </div>
                <div className="col-md-6">
                    <AddPrompt promptId={promptId} setPromptId={setPromptId} getPromptsList={getPromptsList}/>
                </div>
            </div>
            <div className="row">
            <div className="col-md-12">
                    <div className="box table-responsive">
                        <h5>Prompts</h5>                  
                        <table className="table table-bordered table-striped">
                            <thead>
                                <tr>
                                    <th width ="2%">S.NO.</th>
                                    <th width ="83%">Prompt</th>
                                    <th width ="6%">Status</th>
                                    <th width ="8%">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { promptsList && promptsList.length > 0 ? 
                                    promptsList.map( (list,index) => {
                                        return (
                                            <tr key={"prompt"+index}>
                                                <td>{index+1}</td>
                                                <td style={{ whiteSpace: 'pre-line' }}>{list.prompt}</td>
                                                <td>
                                                    <label class="switch">
                                                        <input type="checkbox" onClick={(e) => handlePromptStatus(e, list.id) } checked={list.status == '0' ? false : true}/>
                                                        <span class="slider round"></span>
                                                    </label>
                                                </td>
                                                <td>
                                                    <span style={{cursor:'pointer'}} className="icon icon-sm icon-secondary ml-1"  onClick={() => handleEditPrompt(list.id)}>
                                                        <i className="fa fa-pencil" data-toggle="tooltip" data-placement="right" title="" data-original-title="Edit"></i>
                                                    </span>
                                                    <span style={{cursor:'pointer'}} className="icon icon-sm icon-primary ml-1"  onClick={() => handleDeletePrompt(list.id)}>
                                                        <i className="fa fa-times" data-toggle="tooltip" data-placement="right" title="" data-original-title="Delete"></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :<tr key={"prompt"}><td colSpan='3'>No Data Found!</td></tr>
                                }                                      
                            </tbody>
                        </table>
                    </div>                        
                </div>
            </div>
        </section>
    </Layout>             
    <Add type={popupType} show={show} setShow={setShow} handleClose={handleClose} getList={getList}/>
       
    </>
  );
}