import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import RotateRightIcon from '@mui/icons-material/RotateRight';
import Select, { components } from 'react-select';

export const Section1 = ({ title, type, totals, limit, searchText, setSearchText, handleJobDelete, handleJobRefresh, handleJobRefreshMinor, handleLimitChange, setSubcategory, ...rest }) => {

    const [categories, setCategories] = useState([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        fetchCategoryList();
    }, []);
    
    const fetchCategoryList = () => {
        fetch(process.env.REACT_APP_API_URL + "jobs/categoriesAndSubCategories", {
          method: "GET",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }).then(data => data.json().then((result) => {
              if (result.success) {
                let res = result.data; 
                let categories = [];                 
                  for (const item in res) {
                    let options = []
                    for(let x = 0;  x < res[item].length; x++){                        
                        options.push({ label: res[item][x], value: res[item][x] })
                    }
                    categories.push({label: item, options: options});                    
                }
                setCategories(categories)
                }
            })
            .catch((err) => {
              console.log(err);
            })
        );
    };

    const handleCategoryChange = (selectedCategory) => {
        let categoies = '';
        for(const i of selectedCategory){        
            categoies = categoies.concat("'",i.value,"',")
        }
        setSubcategory(categoies);
        setSelected(selectedCategory)
    }

    const renderValue = (option) => {
        console.log("option====",option)
        // The first three selections are rendered normally
        if (selected.length <= 2) {
          return option.label;
        }
    
        // With more selections, render "N items selected".
        // Other than the first one are hidden in CSS.
        return <span>{selected.length} items selected</span>;
    }
    const className = () => {
        const baseClassName = 'my-react-select';
        
        if (selected.length <= 2) {
          return baseClassName;
        }        
        return `${baseClassName} ${baseClassName}--compact`;
    }

    const ValueContainer = ({ children, ...props }) => {
        let [values, input] = children;
        if (Array.isArray(values)) {
          const val = (i) => values[i].props.children;
          const { length } = values;
         
          switch (length) {
            case 1:
              values = `${val(0)}`;
              break;
            // case 2:
            //   values = `${val(0)} and ${val(1)} selected`;
            //   break;
            // case 3:
            //   values = `${val(0)}, ${val(1)} and ${val(2)} selected`;
            //   break;
            default:
              const plural = values.length === 1 + 1 ? "" : "s";
              const otherCount = length - 1;
            //   values = `${val(0)} and ${otherCount} other${plural} selected`;
                values = `${length} selected`;
              break;
          }
        }
        return (
          <components.ValueContainer {...props}>
            {values}
            {input}
          </components.ValueContainer>
        );
      };

    return (
        <>
        <div className="row align-items-center ss-form-control">
            <div className="col-lg-2 col-md-3 col-6 mb-2">
                <h1 className="main-heading">{title}</h1>
            </div>
            <div className="col-lg-3 col-md-3 col-6 mb-2">
                <div className="search-bar">
                    <input
                        type="text"
                        name="searchText"
                        className="form-control"
                        placeholder="What are you looking for ?"
                        title="What are you looking for ?"
                        onChange={(e) => { 
                            if(e.target.value.length > 3 || e.target.value) {
                                setTimeout( () => { setSearchText(e.target.value) } , 2500);
                            }
                        }} />
                </div>
            </div>
            {
                type == 0 ? 
                    <div className="col-lg-3 col-md-6 col-sm-12 col-12 d-flex align-items-center mb-2">            
                        <label className="me-3 mb-0 ss-font">Remove jobs</label>
                        <select className="form-control" id="remove_jobs">
                        <option value="">--Please Select--</option>
                        <option value="48">Older Than 2 days</option>
                        <option value="72">Older Than 3 days</option>
                        <option value="96">Older Than 4 days</option>               
                        <option value="avg_spent">Avg Spent $5-$100</option>
                        </select>
                        <span onClick={handleJobDelete} className="bg-danger text-white ms-1 rounded"><i className="fa fa-trash trash-icon"></i></span>
                    </div>
                : ""
            }

            <div className="col-lg-3 col-md-6 col-sm-10 col-9 d-flex align-items-center mb-2">            
                <label className="me-2 mb-0 ss-font">Filter Categories</label>
                <div style={{width: "615px"}}>
                    <Select
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                minHeight:'0px',
                                fontSize: '0.7rem',
                                fontWeight: '500',
                                // width: '190px',
                                height:'30px',
                                alignItems:'center',
                            }),
                        }}
                        options={categories}
                        isMulti={true}
                        onChange={(event) => handleCategoryChange(event)}
                        value={selected}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ ValueContainer }}
                    />
                </div>
            </div>             

            { 
                (type === '0' || type === '1' || type === '3') ?
                <>
                    <div className="col-lg-1 cpl-md-1 col-sm-2 col-3 mb-2 px-0 d-flex justify-content-center align-items-center">
                        <div className="col-6 px-0">
                            <div className="icon-center">
                            <img src="assets/img/Loading_icon.gif" alt="Loder" width="50px;" height="50px;" id="loading_img" className="d-none loading_img"/>
                            <a onClick={handleJobRefresh} id="refresh_icon" className="bg-danger text-white refresh-border rounded" title="Refresh/Update jobs">
                                <AutorenewIcon />
                            </a>
                            </div>
                        </div>
                    {/* </div> */}
        
                    {/* <div className="col-1 nopadding" style={{width:"3.333333%"}}> */}
                        {/* <div className="col-6 px-0">
                            <div className="icon-center">
                            <img src="assets/img/Loading_icon.gif" alt="Loder" width="50px;" height="50px;" id="minor_loading_img" className="d-none loading_img"/>
                            <a onClick={handleJobRefreshMinor} id="minor_refresh_icon" title={"minor refresh "+totals.monirRefreshJobs +" jobs"} className="bg-danger text-white refresh-border rounded">
                                <RotateRightIcon />
                            </a>
                            </div>
                        </div> */}
                    </div>
                </>
                : ""
            }
          
        </div>
        </>
    )
};

export default Section1;

Section1.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    totals: PropTypes.object.isRequired,
};