import React, { useState } from "react";
import JobTile from "../JobTile/JobTile";
import { Layout } from "../Layout/Layout";
import Loader from "../Loader/Loader";

export default function JobAssigned() {
  return (
    <>
      <Layout>
        <JobTile title="Assigned Jobs" />
      </Layout>
    </>
  );
}
