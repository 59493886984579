import React, { useEffect, useState }  from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

export const Section2 = ({ type, totals, sortBy, setSortBy, sortingType, setSortingType, filterType, setFilterType, keyword, setKeyword, ...rest }) => {
    
  const [keywordList, setKeywordList] = useState([]);

    useEffect(() => {
        fetchKeywordsList();
    }, []);
    
    const fetchKeywordsList = () => {
        fetch(process.env.REACT_APP_API_URL + "jobs/keywords", {
          method: "GET",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }).then((data) =>
          data
            .json()
            .then((result) => {
              if (result.success) {
                let res = result.data;
                setKeywordList(res);
              } else {
              }
            })
            .catch((err) => {
              console.log(err);
            })
        );
    };
    return(
        <>
            <div className="job-detls" style={{ background: "#fff" }}>
                <div className="row align-items-center">
                    <div className="col-lg-8 col-sm-12 col-xs-12">
                        <div className="rj-card">
                            <div
                            className="d-flex rj-flex  rj-body"
                            style={{ justifyContent: "space-around" }}
                            >
                            <div className="job-title">
                                <h4>Total Jobs</h4>
                                <span className="badge bg-info d-block">
                                {totals.totalJobs}
                                </span>
                            </div>
                            {type === "JBK" ? (
                                <>
                                <div className=" rj-border"></div>
                                <div className="col-8">
                                    <div className="job-title ss-font-color">
                                        <Autocomplete
                                            value={keyword}
                                            onChange={(event, newKeyword) => {
                                            setKeyword(newKeyword);
                                            }}
                                            multiple
                                            id="tags-filled"
                                            options={keywordList.map((option) => option.name)}
                                            freeSolo
                                            renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                placeholder="Search By Keyword"
                                            />
                                            )}
                                        />
                                    </div>
                                </div>
                                </>
                            ) : (
                                <>
                                <div className=" rj-border"></div>
                                <div className="job-title">
                                    <h4>Rejected (Last 24 Hrs)</h4>
                                    <span className="badge bg-danger d-block">
                                    {totals.totalRejectedJobs}
                                    </span>
                                </div>

                                <div className=" rj-border"></div>

                                <div className="job-title">
                                    <h4>Accepted (Last 24 Hrs)</h4>
                                    <span className="badge bg-success d-block">
                                    {totals.totalAcceptedJobs}
                                    </span>
                                </div>

                                <div className=" rj-border"></div>
                                <div className="job-title">
                                    <h4>Approved (Last 24 Hrs)</h4>
                                    <span className="badge bg-success d-block">
                                    {totals.totalApprovedJobs}
                                    </span>
                                </div>

                                <div className=" rj-border"></div>
                                <div className="job-title">
                                    <h4>Bid (Last 24 Hrs)</h4>
                                    <span className="badge bg-success d-block">
                                    {totals.totalBidJobs}
                                    </span>
                                </div>
                                </>
                            )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12">
                        <div className="rj-card">
                            <div className="d-flex rj-flex  rj-body">

                                <div className="job-title">
                                    <h4>Sort by</h4>
                                    <div className="d-flex rj-flex  rj-body ss-align">                      
                                        {/* <select className="form-control" defaultValue={{sortBy}} onChange={(e) => handleSortBy(e.target.value)}> */}
                                        <select className="form-control" defaultValue={{sortBy}} onChange={(e) => setSortBy(e.target.value)}>
                                            {/* <option value="">--Please Select--</option> */}
                                            <option value="time">Time</option>
                                            {/* <option value="client_tot_spent">Total Spent</option> */}
                                            <option value="avg_spent">Avg Spent</option>
                                            <option value="country">Country</option>               
                                            <option value="budget">Budget</option>
                                            <option value="hire_percentage">Hire%</option>
                                        </select>
                                        {
                                            sortingType === "ASC" ?
                                                <span onClick={(e) => setSortingType("DESC")} className={sortingType === "DESC"?"text-secondary":"text-success"} style={{border: '2px',borderStyle: "solid",paddingLeft: '2px',paddingRight: '2px',borderRadius: '2px',margin: '0px 5px'}}><i className="fa fa-arrow-up"></i></span>                        
                                            :
                                                <span onClick={(e) => setSortingType("ASC")} className={sortingType === "ASC"?"text-secondary":"text-success"} style={{border: '2px',borderStyle: "solid",paddingLeft: '2px',paddingRight: '2px',borderRadius: '2px',margin: '0px 5px'}}><i className="fa fa-arrow-down"></i></span>
                                        }                                    
                                    </div>                                    
                                </div>

                                <div className=" rj-border"></div>

                                <div className="job-title">
                                    <h4>Select Category</h4>
                                    <span className={ filterType === "All" ? "mr-2 badge bg-success" : "mr-2 badge bg-secondary" } >
                                        <a onClick={(e) => setFilterType("All")}>All </a>
                                    </span>
                                    <span className={ filterType === "BI" ? "mr-2 badge bg-success" : "mr-2 badge bg-secondary" } >
                                        <a onClick={(e) => setFilterType("BI")}>BI </a>
                                    </span>
                                    <span className={ filterType === "Other" ? "mr-2 badge bg-success" : "mr-2 badge bg-secondary" } >
                                        <a onClick={(e) => setFilterType("Other")}>Other </a>
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
};

export default Section2;

Section2.propTypes = {
    type: PropTypes.string.isRequired,
    totals: PropTypes.object.isRequired,
};